export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/(main)": [6,[3]],
		"/(main)/contacts_home": [9,[3]],
		"/(main)/contacts": [8,[3]],
		"/(main)/contact": [7,[3]],
		"/(ctoken)/ctoken": [5,[2]],
		"/(main)/geolocations": [10,[3]],
		"/(main)/integrations": [11,[3]],
		"/(pos)/pos": [15,[4]],
		"/(main)/preferences": [12,[3]],
		"/(main)/sales_home": [13,[3]],
		"/(main)/setshare": [14,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';